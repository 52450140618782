// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VCounter_XVvL5{align-items:center;display:flex;justify-content:center;transition:color .3s ease,background-color .3s ease;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.VCounter_XVvL5._medium_1H7dU{border-radius:.4rem;font-size:1.1rem;font-weight:700;height:1.6rem;line-height:1.6rem;min-width:1.6rem;padding:0 .4rem}.VCounter_XVvL5._primary_EacYN{background-color:#855aff;color:#fff}.VCounter_XVvL5._white-primary_pVvKu{background-color:#fff;color:#855aff}.VCounter_XVvL5._white-base_wkFpQ{background-color:#fff;color:#17181c}.VCounter_XVvL5._secondary_l4-ub{background-color:#e7deff;color:#855aff}.VCounter_XVvL5._grey_nRx1s{background-color:#f1f1f4;color:#17181c}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VCounter": "VCounter_XVvL5",
	"_medium": "_medium_1H7dU",
	"_primary": "_primary_EacYN",
	"_white-primary": "_white-primary_pVvKu",
	"_white-base": "_white-base_wkFpQ",
	"_secondary": "_secondary_l4-ub",
	"_grey": "_grey_nRx1s"
};
module.exports = ___CSS_LOADER_EXPORT___;
