
// Components
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet';
import VIcon from '~/components/ui/icon/VIcon';

export default {
    name: 'VProfilePicture',
    components: {
        VIcon,
        VImageSrcSet,
    },

    props: {
        image: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            default: 'user',
            validator: v => [
                'image',
                'user',
                'building',
                'building2',
                'number',
                'file',
            ].includes(v),
        },

        size: {
            type: String,
            default: '56',
            validator: v => ['custom', '80', '64', '56', '48', '40', '36', '32', '24'].includes(v),
        },

        imgSizes: {
            type: String,
            default: 'mobile:160px tablet:160px laptop:160px desktop:160px',
        },

        number: {
            type: [String, Number],
            default: null,
        },

        alt: {
            type: String,
            default: 'Изображение',
        },

        /**
         * для type="image"
         */
        contain: {
            type: Boolean,
            default: true,
        },

        round: {
            type: Boolean,
            default: false,
        },

        firstName: {
            type: String,
            default: '',
        },

        lastName: {
            type: String,
            default: '',
        },

        company: {
            type: Boolean,
            default: false,
        },

        color: {
            type: String,
            default: 'primary',
            validator: v => ['primary', 'secondary'].includes(v),
        },
    },

    computed: {
        classList() {
            return [
                this.$style[`_${this.size}`],
                this.$style[`_${this.type}`],
                {
                    [this.$style._round]: this.round,
                    [this.$style._initials]: this.initials,
                    [this.$style._company]: this.company,
                    [this.$style[`_${this.color}`]]: this.color,
                },
            ];
        },

        isIcon() {
            return ['user', 'building', 'building2', 'file'].includes(this.type);
        },

        initials() {
            return `${this.firstName ? this.firstName[0] : ''}`;
        },
    },
};
